@font-face {
  font-family: 'Geist-Regular';
  src: url('./assets/fonts/Geist-Regular.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist-Light';
  src: url('./assets/fonts/Geist-Light.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist-Medium';
  src: url('./assets/fonts/Geist-Medium.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist-SemiBold';
  src: url('./assets/fonts/Geist-SemiBold.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Geist-Bold';
  src: url('./assets/fonts/Geist-Bold.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root{
    --transparent:transparent;
    --white-color:#ffffff;
    --light-white-color:#fafafa;
    --gray-color:var(--text-dark-secondary, rgba(250, 250, 250, 0.55));
    --green-color:#02F57A;
    --red-color:#F96262;
    --point-gray-color:#9AA2A7;
    --disable-color:#58646C;
    --opacity-gray-color:#8C8D8B;
    --bg-gray-color:#191A17;
    --bg-bar-color:#23292D;
    --footer-bg-color:#171717;
    --check-bg-color:#2E2E2E;
    --events-bg-color:#010101;
    --articlelarge-bg-color:#2D3A42;
    --bg-transparent-color:transparent;
    --bg-input-field:#252622;
    --bg-vip-sec:rgba(99, 142, 167, 0.1);
    --bg-perks:rgba(255, 255, 255, 0.05);
    --bg-vip-btn:rgba(255, 255, 255, 0.1);
    --bg-vip-premier:rgba(255, 167, 20, 0.1);
    --bg-vip-ultra:rgba(255, 255, 255, 0.03);
    --bg-customise-first:rgba(255, 255, 255, 0.12);
    --bg-confirm-pop:rgba(29, 33, 31, 1);
    --bg-confirm-popful:rgba(23, 23, 23, 0.75);
    --bg-bottom-events:rgba(0, 0, 0, 0.4);
    --bg-genres-events:rgba(255, 255, 255, 0.06);
    --bg-bash-events:rgba(255, 255, 255, 0.04);
    --bg-vip-plus:linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0.1) 100%);
    --bg-vip-premium:radial-gradient(50% 50% at 50% 50%, #FFF5D4 0%, rgba(255, 245, 212, 0) 100%);
    --bg-vip-ultra-one: radial-gradient(50% 50% at 50% 50%, #4AD8FF 0%, rgba(74, 216, 255, 0) 100%);
    --bg-vip-ultra-two: radial-gradient(50% 50% at 50% 50%, #2AF581 0%, rgba(42, 245, 129, 0) 100%);
    --bg-vip-a-list: linear-gradient(180deg, rgba(104, 224, 93, 1) , rgba(84, 201, 194, 0.33), rgba(122, 51, 119, 1)) 1;
    --bg-customize-act: linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03)), linear-gradient(90deg, rgba(66, 255, 73, 0) 0%, rgba(66, 255, 73, 0.1) 100%);
    --basic-tier:rgba(99, 142, 167, 0.1);
    --plus-tier:rgba(233, 247, 255, 0.1);
    --premium-tier:rgba(255, 167, 20, 0.1);
    --ultra-tier:linear-gradient(180deg, #232323 0%, #232323 100%);
    --bg-articles-act: linear-gradient(180deg, #1F1F1F 4.16%, rgba(31, 31, 31, 0) 49.97%, #1F1F1F 95.78%);
    --bg-vault-sub:rgba(255, 255, 255, 0.05);
    --bg-vault-alist:rgba(46, 46, 46, 1);
    --border-transparent-color:rgba(250, 250, 250, 0.3);
    --border-customize-color:rgba(250, 250, 250, 0.08);
    --border-updates-color:rgba(91, 91, 91, 0.25);
    --border-events-color:#202020;
    --border-notify-color:#30312F;
    --font-Regular:font-family:'Geist-Regular';
    --font-Light:font-family:'Geist-Light';
    --font-Medium:font-family:'Geist-Medium';
    --font-SemiBold:font-family:'Geist-SemiBold';
    --font-Bold:font-family:'Geist-Bold';
    --font-Weight-Regular:font-weight:400;
  }

        html,body{height:100%;}
        #root{height:100%;}
        body {
          margin: 0;
          font-family: 'Geist-Light';
          letter-spacing:1px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        code {font-family: 'Geist-Regular';}
        .font-Regular{font-weight:400;}
        .fontMedium{font-weight:500;}
        .fontRegular{font-family:'Geist-Regular';}
        .fontLight{font-family:'Geist-Light';}
        .fontSemiBold{font-family:'Geist-SemiBold';}  
        .fontBold{font-family:'Geist-Bold';}
        .text-decoration-none{text-decoration: none;}
        .text-line{text-decoration: line-through;}
        .text-center{text-align:center;}
        .text-left{text-align:left;}
        .text-xs{font-size:10px;}
        .text-base{font-size:14px;}
        .text-small{font-size:16px;}
        .text-small-base{font-size:15px;}
        .mb-0{margin-bottom:0;}
        .mb-5{margin-bottom:5px;}
        .mb-10{margin-bottom:10px;}
        .mb-15{margin-bottom:15px;}
        .mb-20{margin-bottom:20px;}
        .mb-25{margin-bottom:25px;}
        .mb-30{margin-bottom:30px;}
        .mb-40{margin-bottom:40px;}
        .mb-60{margin-bottom:60px;}
        .mb-80{margin-bottom:80px;}
        .mb-100{margin-bottom:100px;}
        .mb-150{margin-bottom:150px;}
        .mb-160{margin-bottom:160px;}
        .m-0{margin:0;}
        .mt-0{margin-top:0;}
        .mt-5{margin-top:5px;}
        .mt-10{margin-top:10px;}
        .mt-15{margin-top:15px;}
        .mt-20{margin-top:20px;}
        .mt-25{margin-top:25px;}
        .mt-35{margin-top:35px;}
        .mt-40{margin-top:40px;}
        .ml-5{margin-left:5px;}
        .ml-15{margin-left:15px;}
        .mr-3{margin-right:3px;}
        .mr-5{margin-right:5px;}
        .mr-10{margin-right:10px;}
        .mr-15{margin-right:15px;}
        .mtb-auto{margin:40px auto 40px;}
        .m-auto{margin:auto;}
        .pl-0{padding-left:0;}
        .pl-10{padding-left:10px;}
        .pl-12{padding-left:12px;}
        .pl-14{padding-left:14px;}
        .pl-15{padding-left:15px!important;}
        .pl-16{padding-left:16px;}
        .pl-19{padding-left:19px;}
        .pl-20{padding-left:20px;}
        .pl-25{padding-left:25px;}
        .pl-30{padding-left:30px;}
        .pl-40{padding-left:40px;}
        .pl-50{padding-left:50px;}
        .pr-0{padding-right:0;}
        .pr-10{padding-right:10px;}
        .pr-12{padding-right:12px;}
        .pr-15{padding-right:15px;}
        .pr-16{padding-right:16px;}
        .pr-19{padding-right:19px;}
        .pr-20{padding-right:20px;}
        .pr-25{padding-right:25px;}
        .pr-30{padding-right:30px;}
        .pr-40{padding-right:40px;}
        .pt-5{padding-top:5px;}
        .pt-6{padding-top:6px;}
        .pt-10{padding-top:10px;}
        .pt-11{padding-top:11px;}
        .pt-12{padding-top:12px;}
        .pt-13{padding-top:13px;}
        .pt-15{padding-top:15px;}
        .pt-16{padding-top:16px;}
        .pt-10{padding-top:10px;}
        .pt-20{padding-top:20px;}
        .pt-32{padding-top:32px;}
        .pt-33{padding-top:33px;}
        .pt-80{padding-top:80px;}
        .pb-0{padding-bottom:0;}
        .pb-5{padding-bottom:5px;}
        .pb-10{padding-bottom:10px;}
        .pb-11{padding-bottom:11px;}
        .pb-12{padding-bottom:12px;}
        .pb-13{padding-bottom:13px;}
        .pb-15{padding-bottom:15px;}
        .pb-16{padding-bottom:16px;}
        .pb-20{padding-bottom:20px;}
        .pb-24{padding-bottom:24px;}
        .pb-33{padding-bottom:33px;}
        .pb-100{padding-bottom:100px!important;}
        .pb-150{padding-bottom:150px!important;}
        .p-0{padding:0;}
        .p-10{padding:10px;}
        .p-12{padding:12px;}
        .p-15{padding:15px;}
        .padding-16{padding:16px;}
        .padding-25{padding:25px;} 
        .p-33{padding:33px;}
        .pt-0{padding-top:0;}
        .radius-4{border-radius:4px;}
        .radius-24{border-radius:24px;}
        .radius-999{border-radius:999px;}
        .borderBottom-1{border-bottom:1px solid var(--bg-vault-sub);}
        .borderBottom-2{border-bottom:2px solid var(--white-color);}
        .list {font-size:12px;color:var(--white-color);}
        .opacity-30 {opacity:0.30;}
        .opacity-40 {opacity:0.40;}
        .opacity-50 {opacity:0.50;}
        .opacity-60 {opacity:0.60;}
        .opacity-70 {opacity:0.70;}
        .opacity-90 {opacity:0.90;}
        .opacity-94 {opacity:0.90;}
        .ht-2 {height:2px;}
        .wd-12 {width:12px;}
        .ht-12 {height:12px;}
        .wd-20 {width:20px;}
        .width-30 {width:30px;}
        .wd-33 {width:33.333%;}
        .wd-75 {width:75px;}
        .wd-139 {width:139px;}
        .ht-150 {height:150px;}
        .ht-185 {height:185px;}
        .max-170 {max-width:170px;}
        .max-244 {max-width:244px;}
        .max-250 {max-width:250px;}
        .max-265 {max-width:265px;}
        .max-300 {max-width:300px;}
        .max-320 {max-width:320px;}
        .max-330 {max-width:330px;}
        .max-420 {max-width:420px;}
        .max-450 {max-width:450px;}
        .max-560 {max-width:560px;}
        .max-590 {max-width:590px;}
        .scroll-bar::-webkit-scrollbar{width:6px;height:100%;border-radius:10px;}
        .scroll-bar::-webkit-scrollbar-thumb{background:transparent;border-radius:10px;}
        .scroll-bar::-webkit-scrollbar-track{background:transparent;border-radius:10px;}
        .lineheight-18 {line-height:18px;}
        .nowrap {white-space: nowrap;}
        .break-spaces{white-space: break-spaces;}
        .passwordChangeLogo{width:100px;margin:30% auto 0 auto;}
        .fontSemiBold{font-weight:600;}
        .filter{filter: grayscale(100%);-webkit-filter: grayscale(100%);}
        .absolute{position:absolute;}
        .relative{position:relative;}
        .fixed{position:fixed;}
        .zindex{z-index:1;} 
        .z-10{z-index:10;}
        .flex{display:flex;}
        .block{display:block!important;}
        .none{display:none;}
        .inline-block{display:inline-block;}
        .flex-column{flex-direction: column;}
        .flex-row{flex-direction: row;}
        .flex-wrap{flex-wrap:wrap;}
        .content-between{justify-content:space-between;}
        .content-center{justify-content:center;}
        .content-evenly{justify-content:space-evenly;}
        .content-start{justify-content:flex-start;} 
        .content-end{justify-content:flex-end;}      
        .items-center{align-items:center;}
        .self-start{align-items:self-start;}
        .uppercase {text-transform:uppercase;}
        .capitalize {text-transform:capitalize;}
        .vertical-middle {vertical-align:middle;}
        .overflow-x {overflow-x:auto;}
        .overflowhidden {overflow:hidden;}
        .overflowhide::-webkit-scrollbar {background: transparent;}
        .sets .slick-slide > div {padding: 0 7px;}
        .outlinenone {outline:0 none;}
        .boxshadownone {box-shadow:none;}
        .break-spaces{white-space: break-spaces;}
        .left-0{left:0;}
        .right-0{right:0;}
        .bottom-0{bottom:0;}
        .top-0{top:0;}
        .left-10{left:10px;}
        .left-11{left:11px;}
        .left-12{left:12px;}
        .right-10{right:10px;}
        .bottom-10{bottom:10px;}
        .top-5{top:5px;}
        .top-10{top:10px;}
        .top-12{top:12px;}
        .top-15{top:15px;}
        .left-20{left:20px;}
        .right-20{right:20px;}
        .bottom-20{bottom:20px;}
        .bottom-140{bottom:140px;}
        .top-20{top:20px;}
        .top-22{top:22px;}
        .left-14{left:14px;}
        .right-14{right:14px;}
        .right-15{right:15px;}
        .left-25{left:25px;}
        .right-25{right:25px;}
        .bottom-25{bottom:25px;}
        .bottom-70{bottom:70px;}
        .top-25{top:25px;}
        .w-auto{width:auto !important;}
        .w-85{width:85%!important;}
        .w-96{width:96%!important;}
        .w-full{width:100%;}
        .h-auto{height:auto;}
        .h-2{height:2px;}
        .h-full{height:100%;}
        .h-80vh{height:80vh;}
        .letter-spacing{letter-spacing:1px;}
        .letter-spacing-2{letter-spacing:2px;}
        .pointer-none{pointer-events: none;}

      /*  svg g.pointer {
          display: none;
      }*/
    svg g.doughnut .outerSubArc {display: none;} 
    .GauageCompo svg{overflow: visible;}
    .ultraGradient{object-fit:cover!important;height:100%;}
    /* .grafana-gauge{transform:scale(1.2);padding-top:20px;padding-bottom:20px;} */

    /* .GauageCompo{padding-top:30px;}
    .GauageCompo svg {
      height:280px;
  }

  .GauageCompo g.pointer{
    display: none;
  }

  .vault-one svg g.pointer {
    transform: translate(78px, 48px) !important;
  } */


  .gauge-center-message {
    position: absolute;
    top: 40%;
    left: 0;
    text-align: center;
    width: 62%;
    transform: scale(0.60);
    right: 0;
    color:var(--white-color);
    display: block;
    margin: auto;
  }

  .births .react-datepicker__input-container input {
    width:-webkit-fill-available;
    font-size:16px; 
    border:1px solid var(--bg-input-field);
    background-color:var(--bg-input-field);color:var(--white-color);box-shadow:none;padding:14px 50px 14px 14px;border-radius:6px;box-shadow:none;outline:0 none;
  }
  .births .react-datepicker-wrapper {
    width: -webkit-fill-available;
    border: 1px solid var(--bg-input-field);
    background-color: var(--bg-input-field);
    color: #ffffff;
    box-shadow: none;
    border-radius: 6px;
    padding-left: 35px;
  }
  .births .react-datepicker-wrapper .fill_account {
    font-size: 14px;
  }
  .births .fill_account .react-datepicker__calendar-icon{
    display: none;
  }

  body .react-datepicker{border:none;}
  body .react-datepicker__navigation--years{position:absolute;}
  body .react-datepicker__month-container{background-color:var(--bg-input-field);border:1px solid var(--articlelarge-bg-color);}
  body .react-datepicker__header{color:var(--white-color);background-color:var(--bg-input-field);border-bottom:1px solid var(--border-customize-color);}
  body .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{fill:var(--articlelarge-bg-color);color:var(--articlelarge-bg-color);stroke:var(--articlelarge-bg-color);}
  body .react-datepicker__day-name, body .react-datepicker__day, body .react-datepicker__time-name{color:var(--white-color);}
  body .react-datepicker__day--disabled{color:var(--disable-color);}
  body .react-datepicker__day-name, body .react-datepicker__current-month, body .react-datepicker-time__header, body .react-datepicker-year-header{color:var(--white-color);}
  body .react-datepicker__input-container .react-datepicker__calendar-icon{padding:0;width:26px;height:26px;}
  body .react-datepicker__year-dropdown,body .react-datepicker__month-dropdown,body .react-datepicker__month-year-dropdown{background-color:var(--bg-input-field);}
  .page-header{position:fixed;width:100%;top:0;z-index:99;}
  .page-header.is-sticky{position: fixed;box-shadow: 0 5px 16px var(--bg-bottom-events);background:var(--bg-vip-btn);padding:0 0;backdrop-filter: blur(10px);animation: slideDown 0.35s ease-out;max-width:640px;width:100%;z-index:99;}
  body .react-datepicker__navigation--years-upcoming{border-color:#ccc;border-style:solid;border-width:3px 3px 0 0;transform:rotate(-45deg);right:0;width:12px;height:12px;margin-top:15px;margin-bottom:20px;position:absolute;left:0;top:0;bottom:0;}
  body .react-datepicker__navigation--years-previous{border-color:#ccc;border-style:solid;border-width:3px 3px 0 0;transform:rotate(-45deg);right:0;width:12px;height:12px;margin-top:15px;margin-bottom:20px;position:absolute;left:0;top:0;bottom:0;}
  body .react-datepicker__year-dropdown,body .react-datepicker__month-dropdown,body .react-datepicker__month-year-dropdown{background-color:#191a17;border:1px solid #252622;}
  body .react-datepicker__year-option:hover,body .react-datepicker__month-option:hover,body .react-datepicker__month-year-option:hover{background-color:#292a27;}
  body .react-datepicker__year-option{height:30px;position:relative;}



@keyframes slideDown {
  from {transform: translateY(-100%);}
  to {transform: translateY(0);}
}

.column{flex:50%;max-width:50%;padding:0 4px;}
.column img{margin-top:8px;vertical-align:middle;width:100%;}
.zoom-in-out-box{animation: zoom-in-zoom-out 4s ease infinite;}
  
  @keyframes zoom-in-zoom-out {
    0% {transform: scale(0.6, 0.6);}
    50% {transform: scale(0.8, 0.8);}
    100% {transform: scale(0.6, 0.6);}
  }

  .vault-one svg g.pointer circle {cy: -8.984px !important;}        

  @media (min-width:768px){
    .gauge-center-message{transform: scale(1);width:50%;}
    .page-header{width:640px;}
  }
  @media (max-width:767px){
    .gauge-center-message {transform: scale(1);width:50%;}
    .page-header{max-width:540px;}
  }
  @media (max-width:481px){
    .gauge-center-message {transform: scale(0.8);}
    .page-header{max-width:540px;}
  }
  @media (min-width:320px) and (max-width:480px)
  {
    .page-header{width:100%;}
  } 
